import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export const Snackbarformat: any = {
  duration: 5000,
  verticalPosition: 'top',
  horizontalPosition: 'center',
  panelClass: 'mat-success'
};

export const Snackbarerrorformat: any = {
  duration: 5000,
  verticalPosition: 'top',
  horizontalPosition: 'center',
  panelClass: 'mat-warn'
};

export const NgbModalDefaultOptions: NgbModalOptions = {
  windowClass:"block",
  animation:true,
  centered:true,
  scrollable:true,
  // modalDialogClass:"rounded-xl ",
  size:"md",
};