import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, filter, shareReplay, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Orderline } from '../../_model/order';
import { CalculatedPrice } from '../../_model/pricing';
import { Allergen, Recipe } from '../../_model/recipe';
import { TableParameters, UrlParameters } from '../../_model/shared';
import { RecipeService } from '../../_service/RecipeService';
import { WINDOW, windowProvider } from '../../_service/WindowService';
import { LoaderComponent } from '../loader/loader.component';


@Component({
  selector: 'recipe-list',
  templateUrl: './recipelist.component.html',
  styleUrls: ['./recipelist.component.scss'],
  standalone: true,
  providers: [windowProvider],
  imports: [
    NgIf, NgClass, NgFor, NgTemplateOutlet,
    LoaderComponent,
    TranslateModule
  ]
})
export class RecipelistComponent implements OnInit, AfterContentInit, OnDestroy {

  environment = environment;
  container_class = '';
  scrollPosition = 0;
  scrollEnd = false;

  _lifestyleids: number[];
  @Input() cols = 3;
  @Input() set lifestyles(value: number[]) {
    this._lifestyleids = value ? value.filter(v => v) : []
    if (!this._deliverydate || this.deliverydate == '') {
      this.shownRecipes = this.filterOnLifestyles(this._recipes);
      this.shownRecipesChange.emit(this.shownRecipes);
    } else {

      this.shownRecipes = [];
      this.shownRecipesChange.emit(this.shownRecipes);
      this.currentShownPage = null;
      this.shownPage = 0;
      this.totalPages = 1;

      const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
      const params = tblparams.getUrlParameters();
      this.recipeSearch.next(params);
    }
  }
  _allergens: Allergen[];
  @Input() set allergens(value: Allergen[]) {
    this._allergens = value;
    this.shownRecipes = this.sortRecipes(this.shownRecipes);
  }
  _deliverydate: string;
  @Input() set deliverydate(value: string) {
    //console.log('changing deliverydate', value, this._deliverydate)
    this._deliverydate = value;
    if (value) {
      this.shownRecipes = [];
      this._recipes = [];
      this._searchRecipes = [];
      this.currentShownPage = null;
      this.shownPage = 0;
      this.totalPages = 1;
      const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
      const params = tblparams.getUrlParameters();
      this.recipeSearch.next(params);
    }
  }
  _orderlines: Orderline[]
  @Input() set orderlines(value: Orderline[]) {
    this._orderlines = value;
    if (!this.deliverydate && this._orderlines) {
      const recipeids = this._orderlines.map(a => a.recipeid);
      this.getRecipelist.next(recipeids);
    }
  }
  @Input() set recipes(value: Recipe[]) {

    if (value) {
      this._recipes = [...new Map([...this._recipes, ...value].map(item => [item['id'], item])).values()];
      const filteredRecipes = this.filterOnLifestyles(value)
      if (filteredRecipes) {
        const addedRecipes = this.sortRecipes(filteredRecipes.filter(f => !this.shownRecipes.map(r => r.id).includes(f.id)))
        this.shownRecipes = [...new Map([...this.shownRecipes, ...addedRecipes].map(item => [item['id'], item])).values()];
      }
      this.shownRecipesChange.emit(this.shownRecipes);
    } else {
      this._recipes = [];
      this.shownRecipes = [];
      this.shownRecipesChange.emit(this.shownRecipes);
    }

  }
  @Output() scrollloadChange = new EventEmitter<boolean>();
  @Input() scrollload = true;
  @Input() pagesize = 10;
  @Input() horizontal = false;

  @Input() loading: boolean = false;
  @Input() showallbtn: boolean = true;
  @Input() class: string;

  @Output() recipesChange = new EventEmitter<Recipe[]>();
  @Output() shownRecipesChange = new EventEmitter<Recipe[]>();

  currentShownPage: number = null;
  shownPage: number = 0;
  totalPages: number = 1;
  lifestyleFilter = true;

  shownRecipes: Recipe[] = [];
  _recipes: Recipe[] = [];
  _searchRecipes: Recipe[] = []; // is for keeping the list of searched recipes clean of recipes which are added by the orderlines input

  editable: boolean;
  repeatable = new FormControl(false);
  quantity = new FormControl(0);
  disableShowAction: boolean;
  calculatedPrice: CalculatedPrice;

  error_recipes = false;

  private recipeSearch: Subject<any> = new Subject();
  private getRecipelist: Subject<any> = new Subject();
  private unsubscribe = new Subject<void>();
  @ViewChild('recipelist') recipelistEl: ElementRef;
  @ContentChild('recipe', { read: TemplateRef }) recipeTemplate: TemplateRef<any>;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _recipeService: RecipeService,
    public fb: FormBuilder,
    @Inject(WINDOW) private window: Window,
  ) {
    this.recipeSearch.pipe(

      shareReplay(),
      filter(() => { return (this.shownPage <= this.totalPages) &&(this.currentShownPage !== this.shownPage) && this._deliverydate && this._deliverydate != "" }),
      debounceTime(100),
      switchMap(tblparams => {
        this.currentShownPage = this.shownPage
        this.loading = true;
        return this._recipeService.getRecipeTableByPeriod(tblparams as UrlParameters, this._deliverydate, this._deliverydate);
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(
      result => {
        this.error_recipes = false;
        if (!result) { return; }
        this.shownPage++;
        this.totalPages = result.totalPages;
        this.setScrollEnd();
        //sorts next batch of recipes and adds them to the total list while removing duplicates
        const recipes = this.sortRecipes(result.content);
        if (recipes) {
          this._searchRecipes = [...new Map([...this._searchRecipes, ...recipes].map(item => [item['id'], item])).values()];
          this._recipes = [...new Map([...this._recipes, ...recipes].map(item => [item['id'], item])).values()];
        }

        if (this._orderlines) {
          const recipeids = this._orderlines.map(a => a.recipeid);
          this.getRecipelist.next(recipeids);
        }
        this.recipesChange.emit(this._recipes);

        let filteredRecipes = this.filterOnLifestyles(result.content);

        filteredRecipes = this.sortRecipes(filteredRecipes ? filteredRecipes : []);
        if (filteredRecipes) {
          this.shownRecipes = [...new Map([...this.shownRecipes, ...filteredRecipes].map(item => [item['id'], item])).values()];
        }
        this.shownRecipesChange.emit(this.shownRecipes);

        if (this.shownRecipes && this.shownRecipes.length < 4 && this.shownPage <= this.totalPages + 1) {
          const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
          const params = tblparams.getUrlParameters();
          this.recipeSearch.next(params);
        }

        this.loading = false;
      }, error => {
        this.loading = false;

        this.error_recipes = true;
      })

    this.getRecipelist.pipe(
      shareReplay(),
      switchMap(recipeids => {
        this.loading = true;
        return this._recipeService.getRecipeList(recipeids)
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      this.loading = false;
      this._recipes = [...new Map([...this._searchRecipes, ...result].map(item => [item['id'], item])).values()];
      this.recipesChange.emit(this._recipes);

      //removed sorting bc it let the recipes jump around while selecting
      //sorts recipes without filtering on lifestyle
      // let recipes = this.sortRecipes(result) || []
      // //adds recipes to the start of the array)
      // this.shownRecipes = [...new Map([...recipes, ...this.shownRecipes].map(item => [item['id'], item])).values()];
      // this.shownRecipes = this.shownRecipes.filter(r => this._recipes.map(m => m.id).includes(r.id)); //removes recipes which were in orderlines but are not available in period


      this.shownRecipesChange.emit(this.shownRecipes);
    }, error => { this.loading = false; })

  }

  ngOnInit(): void {
    this.window.addEventListener('scroll', this.onScroll, true);
    if (!this.horizontal) {
      this.container_class = 'h-full grid grid-cols-[repeat(auto-fill,minmax(320px,_1fr))] gap-[25px] mb-32 md:mb-auto';
    } else {
      this.container_class = 'mb-[50px] md:mb-0 relative w-full flex gap-[25px] snap-x snap-proximity overflow-x-auto';
    }

  }
  ngAfterContentInit() {
    if (!this.recipeTemplate) {
      console.error('There is no recipetemplate given: <ng-template #recipe let-recipe></ng-template>')
    }
  }
  ngOnDestroy() {
    this.window.removeEventListener('scroll', this.onScroll, true);
    this.unsubscribe.next();
  }

  hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.get(controlName).hasError(errorName);
  };
  // for infinite scroll behaviour
  onScroll = (event: any): void => {
    if (this.recipelistEl && this.scrollload) {

      if (this.horizontal) {
        this.scrollPosition = this.recipelistEl.nativeElement.scrollLeft;
        this.setScrollEnd();
        const leftSpace = this.recipelistEl.nativeElement.scrollLeftMax - this.recipelistEl.nativeElement.scrollLeft
        if (leftSpace <= 500)
          this.loadMoreRecipes();

      } else {
        const bottomspace = this.recipelistEl.nativeElement.getBoundingClientRect();
        const viewportheight = this.window.innerHeight;
        if (bottomspace.top == 0 && bottomspace.bottom == 0)
          return;
        if (bottomspace.bottom - viewportheight < 500) {
          this.loadMoreRecipes()
        }
      }
    }
  }

  loadMoreRecipes() {
    if (this._deliverydate) {
      const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
      const params = tblparams.getUrlParameters();
      this.recipeSearch.next(params);
    }
    this.scrollloadChange.emit(true);
  }
  setScrollEnd() {
    if (!this.recipelistEl) return
    if (this.recipelistEl.nativeElement.offsetWidth + this.recipelistEl.nativeElement.scrollLeft >= this.recipelistEl.nativeElement.scrollWidth) {
      this.scrollEnd = true;
    } else {
      this.scrollEnd = false;
    }
  }

  showAllRecipes() {
    this.lifestyleFilter = false;
    this.loading = true;

    let addedrecipes = [];
    this._recipes.forEach(r => {
      if (this.shownRecipes.map(c => c.id).indexOf(r.id) === -1) {
        addedrecipes.push(r);
      }
    });

    addedrecipes = this.sortRecipes(addedrecipes);

    this.shownRecipes = [...new Map([...this.shownRecipes, ...addedrecipes].map(item => [item['id'], item])).values()];
    this.shownRecipesChange.emit(this.shownRecipes);

    this.loading = false;
  }
  showLessRecipes() {
    this.lifestyleFilter = true;
    this.loading = true;
    this.shownRecipes = this.filterOnLifestyles(this._recipes);
    this.shownRecipesChange.emit(this.shownRecipes);
    this.loading = false;
  }

  filterOnLifestyles(recipes: Recipe[]): Recipe[] {
    if (!recipes || recipes.length == 0) return [];

    if (this.lifestyleFilter) {
      let filteredrecipes: Recipe[] = [];
      recipes.forEach(r => {
        if (this.fitsInLifestyle(r)) {
          filteredrecipes.push(r)
        }
      });
      //filteredrevcipes = this.sortRecipes(filteredrecipes);

      return filteredrecipes;
    } else {
      return recipes; // this.sortRecipes(recipes);
    }
  }
  fitsInLifestyle(recipe): boolean {
    // checks if the userlifestyles are in the recipelifestyle list
    if (!this._lifestyleids || this._lifestyleids.length === 0) {
      return true;
    }

    let overlap = 0;
    this._lifestyleids.forEach(l => { if (recipe.lifestyles.map(v => v.lifestyleid).indexOf(l) >= 0) { overlap++; } });
    return overlap > 0;
  }

  sortRecipes(recipes: Recipe[]): Recipe[] {
    let _recipes = recipes.sort((a, b) => a.id - b.id);

    if (!_recipes || _recipes.length == 0 && !this._orderlines) {
      return _recipes;
    }

    //sorts _recipes with salessurcharge to the end
    _recipes = _recipes.sort((a, b) => (a.salessurcharge || 0) - (b.salessurcharge || 0))

    if (this._allergens) {
      _recipes = _recipes.sort((a, b) => {
        const acount = a.allergens.map(all => all.name).filter(all => this._allergens.includes(all)).length;
        const bcount = b.allergens.map(all => all.name).filter(all => this._allergens.includes(all)).length;

        if (acount < bcount) { return -1; }
        if (acount > bcount) { return 1; }
        return 0;
      });

    }

    if (this._orderlines) {
      let selectedrecipes = this._recipes.filter(r => this._orderlines.map(a => a.recipeid).includes(r.id));
      //sorts selected _recipes on expiredays
      selectedrecipes = selectedrecipes.sort((a, b) => a.expiredays - b.expiredays);

      // this weard syntax makes a unique list of the selected and filtered _recipes
      _recipes = [...new Map([...selectedrecipes, ..._recipes].map(item => [item['id'], item])).values()];
    }
    return _recipes;
  }

  swipeLeft() {
    this.recipelistEl.nativeElement.scrollTo({ left: this.scrollPosition -= 200, top: 0, behavior: 'smooth' });
  }

  swipeRight() {
    this.recipelistEl.nativeElement.scrollTo({ left: this.scrollPosition += 200, top: 0, behavior: 'smooth' });
  }
}
